.file {
    display: flex;
    align-items: center;
    width: 100%;
    height: 96px;
    border-radius: 8px;
    background: #f3f3f3;
    overflow: hidden;
}

.file.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 94px;
    width: calc(100% - 2px);
    border: 1px solid #d3d3d3;
    background: none;
}

.file.button:hover {
    cursor: pointer;
}

.file.error {
    background: #ffeaea;
}

.imgContainer {
    min-width: 96px;
    width: 96px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.status {
    font-size: 14px;
    margin-top: 8px;
    color: #999999;
}

.status.error {
    color: #f3574f;
}

.name {
    font-weight: 800;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    font-size: 14px;
}

.infoContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

.info {
    margin-left: 16px;
    overflow: hidden;
}

.icon {
    font-size: 18px;
}

.spinner {
    font-size: 22px;
    color: #d3d3d3;
}

.invisible {
    display: none;
}

.hidden {
    visibility: hidden;
}

.deleteButton {
    margin: 0 20px;
}
